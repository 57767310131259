<template>
  <div>
    <b-table
      :items="items"
      :fields="fields"
      bordered
      striped
      hover
      class="apeu-table"
    >
      <template #head(actions)>
        <b-button
          variant="success"
          size="sm"
          block
          class="mr-2"
          title="Adicionar um novo produto relacionado a esse"
          @click="openRelationDialog"
          :disabled="disableForm"
        >
          NOVO
        </b-button>
      </template>
      <template #cell(actions)="row">
        <b-button
          variant="danger"
          size="sm"
          class="mr-2"
          @click="openRemoveDialog(row.item)"
          :disabled="disableForm"
        >
          <b-icon icon="x-circle" />
        </b-button>
      </template>
    </b-table>
    <b-modal
      id="modal-relation"
      size="xl"
      v-model="newRelationDialog"
      centered
      hide-footer
      hide-header
    >
      <b-table
        :items="relationList"
        :fields="fields"
        :busy="isLoadingSearch"
        :style="{ borderTop: 'hidden' }"
        empty-text="Não ha produtos para mostrar"
        striped
        hover
      >
        <!-- Search -->
        <template #thead-top>
          <b-tr>
            <b-th colspan="3">
              <search-input
                v-model="search"
                placeholder="Pesquise um produto"
                :is-loading-search="isLoadingSearch"
                :disabled="disableForm"
                initialFocus
                full
                @enter="onSearch"
              />
            </b-th>
          </b-tr>
        </template>

        <!-- Loading -->
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Buscando...</strong>
          </div>
        </template>

        <!-- Button -->
        <template #cell(actions)="row">
          <b-button
            variant="success"
            size="sm"
            class="mr-2"
            @click="newRelation(row.item.id)"
            :disabled="disableForm"
            v-if="!row.item.inserted"
          >
            <b-icon icon="check" />
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            class="mr-2"
            @click="openRemoveDialog(row.item)"
            :disabled="disableForm"
            v-else
          >
            <b-icon icon="x-circle" />
          </b-button>
        </template>
      </b-table>
      <b-button
        @click="getMore"
        block
        variant="primary"
        :disabled="!disableButton"
      >
        <b-icon icon="arrow-clockwise" animation="spin" v-if="isLoading" />
        CARREGAR MAIS
      </b-button>
    </b-modal>
    <custom-modal
      v-model="removeDialog"
      title="Tem certeza?"
      :message="message"
      @confirm="removeRelation"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import CustomModal from "../shared/CustomModal.vue";
import SearchInput from "../shared/SearchInput.vue";

export default {
  components: { CustomModal, SearchInput },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  name: "RelationsTable",
  data() {
    return {
      newRelationDialog: false,
      removeDialog: false,
      idToRemove: 0,
      search: "",
      page: 2,
      message: "",
      fields: [
        {
          key: "productCode",
          label: "Código",
          sortable: true,
        },
        {
          key: "completeDescription",
          label: "Descrição",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ],
    };
  },
  computed: {
    ...mapState("product", ["products", "isLoadingSearch", "isLoading"]),
    ...mapGetters("auth", ["userPermission"]),

    disableForm() {
      return this.userPermission([1, 3]);
    },
    disableButton() {
      return this.relationList.length > 1;
    },
    relationList() {
      return this.products.map((e) => ({
        ...e,
        inserted: this.items.find((item) => item.id === e.id),
      }));
    },
  },
  methods: {
    ...mapActions("product", ["searchProducts", "getMoreSearch"]),
    ...mapMutations("product", ["resetProductList"]),

    openRemoveDialog({ id, productCode }) {
      this.message = `Deseja remover a relação com o produto: ${productCode}?`;
      this.removeDialog = true;
      this.idToRemove = id;
    },
    openRelationDialog() {
      this.resetProductList();
      this.search = "";
      this.newRelationDialog = true;
    },
    async onSearch() {
      const { search } = this;
      this.page = 2;
      if (search) {
        await this.searchProducts(search);
      }
    },
    async getMore() {
      const { page, search } = this;

      await this.getMoreSearch({ page, search });

      this.page = page + 1;
    },
    newRelation(id) {
      this.$emit("new-relation", id);
    },
    removeRelation() {
      const { idToRemove } = this;
      if (idToRemove) {
        this.$emit("remove-relation", idToRemove);
      }
      this.removeDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.apeu-table::v-deep tr th:last-child {
  width: 8%;
}
</style>
