<template>
  <div>
    <b-table :items="formatedItems" :fields="fields" bordered striped hover />
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  name: "PriceHistoryTable",
  data() {
    return {
      dialog: false,
      search: "",
      page: 2,
      fields: [
        {
          key: "oldPrice",
          label: "Preço anterior",
          sortable: true,
        },
        {
          key: "newPrice",
          label: "Novo preço",
          sortable: true,
        },
        {
          key: "modificationDate",
          label: "Data de modifiação",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    formatedItems() {
      const optionDate = {
        year: "numeric",
        month: "long" || "short" || "numeric",
        weekday: "long" || "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };

      const optionPrice = {
        style: "currency",
        currency: "BRL",
      };

      return this.items.map((e) => ({
        ...e,
        oldPrice: new Intl.NumberFormat("pt-BR", optionPrice).format(
          e.oldPrice
        ),
        newPrice: new Intl.NumberFormat("pt-BR", optionPrice).format(
          e.newPrice
        ),
        modificationDate: new Date(e.modificationDate).toLocaleDateString(
          "pt-BR",
          optionDate
        ),
      }));
    },
  },
};
</script>

<style></style>
