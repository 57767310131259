<template>
  <div>
    <b-table
      class="apeu-table"
      :items="conversions"
      :fields="fields"
      bordered
      striped
      hover
    >
      <template #head(actions)>
        <b-button
          variant="success"
          size="sm"
          block
          class="mr-2"
          @click="openCreateConversionModal"
          :disabled="disableForm"
        >
          NOVO
        </b-button>
      </template>
      <template #cell(actions)="row">
        <b-button
          variant="primary"
          size="sm"
          class="mr-2"
          @click="openEditConversionModal(row.item)"
          :disabled="disableForm"
        >
          <b-icon icon="pencil" />
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          class="mr-2"
          @click="openRemoveConversionModal(row.item.id)"
          :disabled="disableForm"
        >
          <b-icon icon="x-circle" />
        </b-button>
      </template>
    </b-table>
    <b-modal
      id="modal-center"
      title="Novo Conversão"
      v-model="showFormModal"
      size="xl"
      centered
      hide-footer
      hide-header
    >
      <b-form @submit.prevent="onSubmitAttribute" class="mb-3">
        <form-input
          v-model="form.brand"
          label="Marca:"
          placeholder="Digite a marca"
          icon="building"
          required
          :disabled="disableForm"
          :errors="errors['brand']"
        />

        <form-input
          v-model="form.brandCode"
          label="Código:"
          placeholder="Digite o código da marca"
          icon="three-dots"
          required
          :disabled="disableForm"
          :errors="errors['brandCode']"
        />

        <form-input
          v-model="productConversion"
          label="Produto de conversão:"
          placeholder="Adicione um produto"
          required
          disabled
          append-icon="x"
          append-variant="danger"
          :errors="errors['conversionId']"
          @apped-click="removeSelection"
        />

        <b-button type="submit" variant="primary">SALVAR</b-button>
      </b-form>
      <!-- Search -->
      <search-input
        v-model="search"
        placeholder="Pesquise um produto"
        :disabled="disableForm"
        :is-loading-search="isLoadingSearch"
        full
        @enter="onSearch"
      />

      <b-table
        :items="products"
        :fields="fieldsProduct"
        :busy="isLoadingSearch"
        :style="{ borderTop: 'hidden' }"
        empty-text="Não ha produtos para mostrar"
        striped
        hover
      >
        <!-- Loading -->
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Buscando...</strong>
          </div>
        </template>

        <!-- Button -->
        <template #cell(actions)="row">
          <b-button
            variant="success"
            size="sm"
            class="mr-2"
            @click="setSelection(row.item)"
            :disabled="disableForm"
            v-if="!row.item.inserted"
            :pressed="row.item.id === form.conversionId"
          >
            <b-icon icon="check" />
          </b-button>
        </template>
      </b-table>
    </b-modal>
    <custom-modal
      v-model="showRemoveModal"
      title="Tem certeza?"
      message="Deseja apagar Conversão"
      @confirm="removeConversion"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import http from "../../plugins/http";
import FormInput from "../form/FormInput.vue";
import CustomModal from "../shared/CustomModal.vue";
import SearchInput from "../shared/SearchInput.vue";

export default {
  name: "ConversionForm",
  components: { CustomModal, FormInput, SearchInput },
  props: {
    conversions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showFormModal: false,
      showRemoveModal: false,
      idToRemove: 0,
      search: "",
      page: 2,
      errors: {},
      form: {
        id: 0,
        productId: Number(this.$route.params.id),
        conversionId: null,
        brand: "",
        brandCode: "",
        conversion: null,
      },
      fields: [
        {
          key: "brand",
          label: "Marca",
          sortable: true,
        },
        {
          key: "brandCode",
          label: "Código",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ],
      fieldsProduct: [
        {
          key: "productCode",
          label: "Código",
          sortable: true,
        },
        {
          key: "completeDescription",
          label: "Descrição",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ],
    };
  },

  computed: {
    ...mapGetters("auth", ["userPermission"]),
    ...mapState("product", ["products", "isLoadingSearch", "isLoading"]),

    disableForm() {
      return this.userPermission([1, 3]);
    },
    productConversion() {
      const { form } = this;
      return form.conversion
        ? `${form.conversion.productCode}: ${form.conversion.completeDescription}`
        : "";
    },
  },
  methods: {
    ...mapActions("product", ["searchProducts", "getMoreSearch"]),
    ...mapMutations(["setToastedSuccess", "setToastedError"]),
    ...mapMutations("product", ["resetProductList"]),

    resetForm() {
      this.errors = {};
      this.form = {
        id: 0,
        productId: Number(this.$route.params.id),
        conversionId: null,
        brand: "",
        brandCode: "",
        conversion: null,
      };
    },
    openCreateConversionModal() {
      this.resetProductList();
      this.resetForm();
      this.showFormModal = true;
    },
    openEditConversionModal(values) {
      this.resetProductList();
      this.form = { ...values };
      this.errors = {};
      this.showFormModal = true;
    },
    openRemoveConversionModal(id) {
      this.showRemoveModal = true;
      this.idToRemove = id;
    },
    async onSearch() {
      const { search } = this;
      this.page = 2;
      if (search) {
        await this.searchProducts(search);
      }
    },
    setSelection(product) {
      this.form.conversionId = product.id;
      this.form.conversion = {
        id: product.id,
        productCode: product.productCode,
        completeDescription: product.completeDescription,
      };
    },
    removeSelection() {
      this.form.conversionId = null;
      this.form.conversion = null;
    },
    async create() {
      this.errors = {};
      try {
        const { form } = this;
        const { data } = await http.post("conversion", {
          productId: form.productId,
          conversionId: form.conversionId,
          brand: form.brand,
          brandCode: form.brand,
        });
        this.$emit("create-conversion", data);
        this.setToastedSuccess({
          message: "Conversão foi criada com sucesso",
        });
        this.resetForm();
      } catch (err) {
        if (err.response.status === 406) {
          this.errors = err.response.data.error;
        } else {
          this.setToastedError({
            message: err.response.data.error,
          });
        }
      }
    },
    async update() {
      this.errors = {};
      try {
        const { form } = this;
        const url = `conversion/${this.form.id}`;
        const { data } = await http.put(url, {
          productId: form.productId,
          conversionId: form.conversionId,
          brand: form.brand,
          brandCode: form.brand,
        });
        this.$emit("update-conversion", data);
        this.setToastedSuccess({
          message: "Conversão foi atualizada com sucesso",
        });
        this.resetForm();
      } catch (err) {
        if (err.response.status === 406) {
          this.errors = err.response.data.error;
        } else {
          this.setToastedError({
            message: err.response.data.error,
          });
        }
      }
    },
    async remove(id) {
      try {
        await http.delete(`conversion/${id}`);
        this.$emit("remove-conversion", id);
        this.setToastedSuccess({
          message: "Conversão foi removida com sucesso",
        });
      } catch (err) {
        this.setToastedError({
          message: err.response.data.error,
        });
      }
    },
    async onSubmitAttribute() {
      if (this.form.id) {
        await this.update();
      } else {
        await this.create();
      }
    },
    removeConversion() {
      const { idToRemove } = this;

      if (idToRemove) {
        this.remove(idToRemove);
      }

      this.showRemoveModal = false;
    },
  },
};
</script>

<style scss scoped>
.apeu-table::v-deep tr th:last-child {
  width: 8%;
}
</style>
