<template>
  <div>
    <b-table :items="verifyAttributes" :fields="fields" bordered striped hover>
      <template #head(actions)>
        <b-button
          variant="success"
          size="sm"
          title="Criar um atributo"
          @click="openCreateAttributeModal"
          :disabled="disableForm"
        >
          CRIAR
        </b-button>
        <b-button
          variant="success"
          size="sm"
          class="ml-2"
          title="Adicionar um perfil de atributos"
          @click="openCreateProductProfile"
          :disabled="disableForm"
        >
          ADICIONAR UM PERFIL
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          class="ml-2"
          title="Deletar todos os atributos"
          @click="openDeleteAttributeModal"
          :disabled="disableForm"
        >
          LIMPAR
        </b-button>
      </template>
      <template #cell(actions)="row">
        <b-button
          variant="primary"
          size="sm"
          class="mr-2"
          title="Editar atributo"
          @click="openEditAttributeModal(row.item)"
          :disabled="disableForm"
        >
          <b-icon icon="pencil" />
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          class="mr-2"
          title="Excluir atributo"
          @click="openRemoveAttributeModal(row.item.id)"
          :disabled="disableForm"
        >
          <b-icon icon="x-circle" />
        </b-button>
        <b-button
          :variant="row.item.filter ? 'success' : 'danger'"
          size="sm"
          class="mr-2"
          title="Fazer deste atributo um filtro"
          @click="toggleFilter(row.item)"
          :disabled="disableForm"
        >
          <b-icon :icon="row.item.filter ? 'funnel-fill' : 'funnel'" />
        </b-button>
      </template>
    </b-table>
    <b-modal
      hide-footer
      v-model="showProfileModal"
      title="Perfil de Produtos"
      @hide="resetProfileModal"
    >
      <form-select
        :value="productProfileID"
        :options="productProfiles"
        text-field="description"
        value-field="id"
        label="Selecione o Perfil:"
        placeholder="Digite o atributo"
        icon="card-text"
        spellcheck
        required
        :disabled="disableForm"
        @change="selectProductProfileAttributes"
      />
      <b-table
        :items="productProfilesAttributes"
        :fields="profileAttributeFields"
        hover
        small
      >
        <template #cell(value)="row">
          <form-input
            v-model="row.item.value"
            :icon="row.item.typeValue === 'textValue' ? 'type' : 'calculator'"
            :type="row.item.typeValue === 'textValue' ? 'text' : 'number'"
          />
        </template>
      </b-table>
      <b-button block @click="createProductProfileAttribute" variant="success">
        SALVAR
      </b-button>
    </b-modal>
    <b-modal
      id="modal-center"
      title="Novo Atributo"
      centered
      hide-footer
      v-model="showFormModal"
    >
      <b-form @submit.prevent="onSubmitAttribute">
        <form-input
          v-model="form.attribute"
          label="Atributo:"
          placeholder="Digite o atributo"
          icon="card-text"
          spellcheck
          required
          :disabled="disableForm"
          :errors="errors['attribute']"
        />
        <form-input
          v-model="form.value"
          label="Valor:"
          placeholder="Digite o valor"
          icon="card-text"
          spellcheck
          required
          :disabled="disableForm"
          :errors="errors['value']"
        />
        <b-button type="submit" variant="primary">SALVAR</b-button>
      </b-form>
    </b-modal>
    <custom-modal
      v-model="showRemoveModal"
      title="Tem certeza?"
      message="Deseja apagar atributo"
      @confirm="removeAtributo"
    />

    <custom-modal
      v-model="showDeleteAllAttributesModal"
      title="ATENÇÃO"
      message="ESSA AÇÃO IRA DELETAR DE MANEIRA IRREVERSÍVEL TODOS OS ATRIBUTOS DESSE PRODUTO. AS CONSEQUÊNCIAS DESSE ATO SERÃO DE SUA TOTAL RESPONSABILIDADE. VOCÊ TEM CERTEZA?"
      @confirm="deleteAllProductAttributes"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import FormInput from "../form/FormInput.vue";
import CustomModal from "../shared/CustomModal.vue";
import FormSelect from "../form/FormSelect.vue";
import http from "../../plugins/http.js";

export default {
  components: { CustomModal, FormInput, FormSelect },
  props: {
    items: {
      type: Array,
      required: true,
    },
    productId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showDeleteAllAttributesModal: false,
      productProfiles: [],
      productProfileID: 0,
      productProfilesAttributes: [],
      showProfileModal: false,
      showFormModal: false,
      showRemoveModal: false,
      idToRemove: 0,
      form: {
        id: 0,
        attribute: "",
        value: "",
      },
      errors: {},
      fields: [
        {
          key: "attribute",
          label: "Atributo",
          sortable: true,
          thStyle: { width: "500px !important" },
        },
        {
          key: "value",
          label: "Valor",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          thStyle: { width: "325px !important" },
        },
      ],

      profileAttributeFields: [
        { key: "attribute", label: "Atributo" },
        { key: "value", label: "Valor" },
      ],
    };
  },
  computed: {
    ...mapGetters("auth", ["userPermission"]),

    disableForm() {
      return this.userPermission([1, 3]);
    },
    verifyAttributes() {
      const testFn = (testItem, item) =>
        testItem.attribute.toLowerCase() == item.attribute.toLowerCase();

      return this.items.map((e, i) => ({
        ...e,
        _rowVariant:
          this.items.findIndex((testItem) => testFn(testItem, e)) !== i &&
          "danger",
      }));
    },
  },
  methods: {
    ...mapMutations(["setToasted", "setOverlay"]),

    resetProfileModal() {
      this.productProfilesAttributes = [];
    },
    openDeleteAttributeModal() {
      this.showDeleteAllAttributesModal = true;
    },

    openCreateAttributeModal() {
      this.showFormModal = true;
      this.form = {
        attribute: "",
        value: "",
        id: 0,
      };
    },
    openEditAttributeModal(values) {
      this.showFormModal = true;
      this.form = values;
    },
    async openCreateProductProfile() {
      const { data: productProfiles } = await http(
        "/product/profile/get-all-list"
      );
      this.productProfiles = productProfiles;
      this.showProfileModal = true;
    },
    openRemoveAttributeModal(id) {
      this.showRemoveModal = true;
      this.idToRemove = id;
    },

    async createAttribute(form) {
      this.errors = [];
      try {
        const id = this.$route.params.id;
        const url = `product/${id}/attributes`;
        const { data } = await http.post(url, {
          attribute: form.attribute,
          value: form.value,
        });
        this.setToasted({
          title: "SUCESSO",
          message: "Atributo foi criado com sucesso",
          variant: "success",
          visible: true,
        });
        this.$emit("create-attribute", data);
        this.showFormModal = false;
      } catch (err) {
        if (err.response.status === 406) {
          this.errors = err.response.data.error;
        } else {
          this.setToasted({
            title: "ERRO",
            message: err.response.data.error,
            variant: "danger",
            visible: true,
          });
        }
      }
    },
    async updateAttribute(form) {
      this.errors = [];
      try {
        const url = `product/updateProductAttributes/${form.id}`;
        const { data } = await http.put(url, {
          attribute: form.attribute,
          value: form.value,
        });

        this.setToasted({
          title: "SUCESSO",
          message: "Atributo foi atualizado com sucesso",
          variant: "success",
          visible: true,
        });
        this.$emit("update-attribute", data);
        this.showFormModal = false;
      } catch (err) {
        if (err.response.status === 406) {
          this.errors = err.response.data.error;
        } else {
          this.setToasted({
            title: "ERRO",
            message: err.response.data.error,
            variant: "danger",
            visible: true,
          });
        }
      }
    },

    async onSubmitAttribute() {
      const { form } = this;

      if (form.id) {
        await this.updateAttribute(form);
      } else {
        await this.createAttribute(form);
      }

      this.form = {
        attribute: "",
        value: "",
        id: 0,
      };
    },
    toggleFilter(attribute) {
      this.$emit("filter-attribute", attribute);
    },
    removeAtributo() {
      const { idToRemove } = this;

      if (idToRemove) {
        this.$emit("remove-attribute", idToRemove);
      }

      this.showRemoveModal = false;
    },
    async selectProductProfileAttributes(id) {
      const { data } = await http(`/product/profile/attribute/${id}`);
      this.productProfilesAttributes = data;
    },
    async createProductProfileAttribute() {
      try {
        const data = this.productProfilesAttributes.map((value) => ({
          value: `${value.value || ""} ${value.standardMeasurementUnit || ""}`,
          attribute: value.attribute,
          filter: value.filter,
        }));

        await http.post(`/product/createManyAttributes/${this.productId}`, {
          data,
        });

        this.$emit("create-many-attributes", data);
        this.showProfileModal = false;
        this.productProfilesAttributes = [];
      } catch (error) {
        this.showProfileModal = false;
      }
    },

    async deleteAllProductAttributes() {
      try {
        await http.delete(`/product/deleteAttributes/${this.productId}`);

        this.$emit("delete-all-attributes");
        this.showDeleteAllAttributesModal = false;
      } catch (error) {
        this.showDeleteAllAttributesModal = false;
      }
    },
  },

  async created() {
    const { data: productProfiles } = await http("/product/profile/");
    this.productProfiles = productProfiles;
  },
};
</script>

<style scss scoped></style>
