<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-form>
          <b-form-group label="Imagens:" label-for="textarea">
            <b-form-file
              v-model="imagesToUpload"
              placeholder="Selecione as imagens"
              accept="image/jpeg, image/png, image/webp"
              multiple
              @input="uploadImages"
            />
          </b-form-group>
        </b-form>
        <b-container fluid>
          <b-row>
            <b-col v-for="image in images" :key="image.id" sm="6" md="3">
              <b-card
                class="shadow-sm mb-3"
                :img-src="image.imgUrl"
                align="right"
                overlay
                @click="openShowImageModal(image.imgUrl)"
              >
                <b-card-text>
                  <b-button
                    @click.stop="openRemoveImageModal(image.id)"
                    class="m-auto"
                    size="sm"
                  >
                    <b-icon icon="trash" />
                  </b-button>
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-modal v-model="showImageDialog" title="Imagem" size="lg" hide-footer>
      <b-img :src="imageToShow" />
      <form-input :value="imageToShow" icon="card-image" copyable disabled />
    </b-modal>
    <custom-modal
      v-model="removeDialog"
      title="Tem certeza?"
      message="Deseja remover essa imagem."
      @confirm="removeImage"
    />
  </b-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import http from "../../plugins/http";
import FormInput from "../form/FormInput.vue";
import CustomModal from "../shared/CustomModal.vue";

export default {
  components: { CustomModal, FormInput },
  name: "UploadImageForm",
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      imageToShow: null,
      imagesToUpload: null,
      showImageDialog: false,
      removeDialog: false,
      idImageToRemove: null,
    };
  },
  computed: {
    ...mapState("product", ["product"]),
  },
  methods: {
    ...mapMutations(["setToastedSuccess", "setToastedError"]),

    async uploadImages() {
      try {
        const { imagesToUpload, product } = this;
        const formData = new FormData();

        for (const image of imagesToUpload) {
          formData.append("images", image);
        }

        formData.append("productCode", product.productCode);
        formData.append("productId", product.id);

        const { data } = await http.post("/image/product", formData);

        this.$emit("added-images", data);
        this.setToastedSuccess({
          message: "As imagens foram enviadas com sucesso",
        });
      } catch (err) {
        this.setToastedError({
          message: err.response.data.error,
        });
      }
    },
    async removeImage() {
      try {
        const { idImageToRemove } = this;
        await http.delete(`/image/product/${idImageToRemove}`);
        this.$emit("remove-image", idImageToRemove);
        this.setToastedSuccess({
          message: "A imagem foi removida com sucesso",
        });
      } catch (err) {
        this.setToastedError({
          message: err.response.data.error,
        });
      }
      this.removeDialog = !this.removeDialog;
    },
    openRemoveImageModal(id) {
      this.idImageToRemove = id;
      this.removeDialog = !this.removeDialog;
    },
    openShowImageModal(link) {
      this.imageToShow = link;
      this.showImageDialog = !this.showImageDialog;
    },
  },
};
</script>

<style></style>
