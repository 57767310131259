<template>
  <b-container class="mb-4" fluid>
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex flex-column justify-content-center">
            <h3>Detalhes do produto</h3>
            <span> {{ pageHeader.code }} - {{ pageHeader.desc }} </span>
          </div>

          <search-input
            v-model="searchProduct"
            :is-loading-search="isLoadingSearch"
            @enter="search"
          />
        </div>
        <b-tabs fill>
          <b-tab title="GERAL" class="mt-3">
            <b-row>
              <b-col>
                <b-form @submit="createProduct">
                  <b-row>
                    <b-col>
                      <form-input
                        v-model="form.productCode"
                        label="Código:"
                        icon="three-dots"
                        disabled
                        copyable
                      />
                    </b-col>
                    <b-col>
                      <form-input
                        v-model="form.barcode"
                        label="Código de Barra:"
                        icon="upc"
                        disabled
                      />
                    </b-col>
                    <b-col v-if="userPermission([1, 2])">
                      <form-input
                        :value="
                          new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(form.costPrice)
                        "
                        label="Preço de custo:"
                        icon="cash"
                        disabled
                      />
                    </b-col>
                    <b-col>
                      <form-input
                        :value="
                          new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(form.price)
                        "
                        label="Preço de venda:"
                        icon="cash"
                        disabled
                      />
                    </b-col>
                    <b-col>
                      <form-input
                        :value="`${form.stock} ${form.unity}`"
                        label="Estoque atual:"
                        icon="columns"
                        disabled
                      />
                    </b-col>
                  </b-row>

                  <form-input
                    v-model="form.completeDescription"
                    label="Descrição completa:"
                    placeholder="Digite a descrição"
                    icon="card-heading"
                    :disabled="disableForm"
                    :errors="errorsProduct['completeDescription']"
                    required
                    spellcheck
                    copyable
                  />

                  <b-row>
                    <b-col>
                      <form-input
                        v-model="form.description"
                        label="Descrição SGI:"
                        icon="clipboard-minus"
                        disabled
                        copyable
                      />
                    </b-col>
                    <b-col cols="3">
                      <form-input
                        :value="form.ncm"
                        label="NCM:"
                        icon="credit-card2-front"
                        disabled
                      />
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <form-input
                        v-model="form.reference"
                        label="Referência:"
                        placeholder="Digite a referência"
                        icon="card-list"
                        :disabled="disableForm"
                        :errors="errorsProduct['reference']"
                        spellcheck
                      />
                    </b-col>
                    <b-col>
                      <form-input
                        :value="form.manufacturer"
                        label="Fabricante:"
                        icon="building"
                        disabled
                      />
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="3">
                      <form-input
                        v-model="form.quantityPackage"
                        label="Quantidade na embalagem:"
                        placeholder="Digite a quantidade"
                        icon="box-seam"
                        :disabled="disableForm"
                        :errors="errorsProduct['quantityPackage']"
                      />
                    </b-col>
                    <b-col cols="3">
                      <form-input
                        v-model="form.unity"
                        label="Unidade na embalagem:"
                        placeholder="Digite a unidade"
                        icon="collection"
                        :disabled="disableForm"
                        :errors="errorsProduct['unity']"
                      />
                    </b-col>
                    <b-col cols="6">
                      <form-select
                        v-model="form.boxId"
                        label="Caixa:"
                        icon="box"
                        :options="formatedBoxes"
                        :disabled="disableForm"
                        :errors="errorsProduct['boxId']"
                      />
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <form-number
                        v-model="form.height"
                        label="Altura (CM):"
                        placeholder="Informe a altura em centimetros"
                        icon="rulers"
                        :disabled="disableForm"
                        :errors="errorsProduct['height']"
                      />
                    </b-col>
                    <b-col>
                      <form-number
                        v-model="form.width"
                        label="Largura (CM):"
                        placeholder="Informe a largura em centimetros"
                        icon="rulers"
                        :disabled="disableForm"
                        :errors="errorsProduct['width']"
                      />
                    </b-col>
                    <b-col>
                      <form-number
                        v-model="form.length"
                        label="Comprimento (CM):"
                        placeholder="Informe a comprimento em centimetros"
                        icon="rulers"
                        :disabled="disableForm"
                        :errors="errorsProduct['length']"
                      />
                    </b-col>
                    <b-col>
                      <form-input
                        :value="form.weight && `${form.weight} G`"
                        label="Peso:"
                        placeholder="Produto sem peso"
                        icon="stack"
                        disabled
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <form-select
                        v-model="form.departmentId"
                        label="Departamento:"
                        :options="departments"
                        text-field="description"
                        value-field="id"
                        icon="diagram3"
                        :disabled="disableForm"
                        :errors="errorsProduct['departmentId']"
                        required
                      />
                    </b-col>
                    <b-col>
                      <form-select
                        v-model="form.catalogId"
                        label="Catalogo:"
                        :options="catalogs"
                        text-field="description"
                        value-field="id"
                        icon="book-half"
                        :disabled="disableForm"
                        :errors="errorsProduct['catalogId']"
                        required
                      />
                    </b-col>
                    <b-col>
                      <form-select
                        v-model="form.groupId"
                        label="Grupo:"
                        :options="groups"
                        text-field="group"
                        value-field="id"
                        icon="journal-bookmark"
                        :disabled="disableForm"
                        :errors="errorsProduct['groupId']"
                        required
                      />
                    </b-col>
                  </b-row>

                  <div class="d-flex justify-content-end">
                    <b-button
                      type="submit"
                      variant="primary"
                      :disabled="disableForm"
                    >
                      GRAVAR ALTERAÇÕES
                    </b-button>
                  </div>
                </b-form>
              </b-col>
              <b-col cols="3">
                <b-col>
                  <h5>Foto do Produto</h5>
                  <b-row>
                    <image-fallback
                      :key="showImage"
                      :image="image"
                      alt="Responsive image"
                    />
                  </b-row>
                </b-col>
                <b-col>
                  <h5>Configurações de Exibição</h5>
                  <b-row>
                    <b-form-checkbox
                      id="checkbox-1"
                      name="checkbox-1"
                      v-model="form.showIpi"
                      :disabled="disableForm"
                    >
                      Exibir IPI
                    </b-form-checkbox>
                  </b-row>
                  <b-row>
                    <b-form-checkbox
                      id="checkbox-3"
                      name="checkbox-3"
                      v-model="form.news"
                      :disabled="disableForm"
                    >
                      Novidade
                    </b-form-checkbox>
                  </b-row>
                </b-col>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="ATRIBUTOS" class="mt-3" lazy>
            <attributes-table
              :productId="Number($route.params.id)"
              :items="form.attributes"
              @create-attribute="createAtributo"
              @update-attribute="updateAtributo"
              @filter-attribute="filterAtributo"
              @remove-attribute="removeAtributo"
              @create-many-attributes="createManyAttributes"
              @delete-all-attributes="deleteAllAttributes"
            />
          </b-tab>
          <b-tab title="APLICAÇÕES" class="mt-3">
            <application-form :application="form.application[0] || {}" />
          </b-tab>
          <b-tab title="CONVERSÕES" class="mt-3" lazy>
            <conversion-form
              :conversions="form.conversion"
              @create-conversion="createConversion"
              @update-conversion="updateConversion"
              @remove-conversion="removeConversion"
            />
          </b-tab>
          <b-tab title="RELACIONADOS" class="mt-3" lazy>
            <relations-table
              :items="relations"
              @new-relation="createRelation"
              @remove-relation="removeRelation"
            />
          </b-tab>
          <b-tab title="MARCAÇÕES" class="mt-3" lazy>
            <form-textarea
              v-model="form.tags"
              label="Marcações (Tags):"
              placeholder="Digite as tags:"
              rows="10"
              max-rows="6"
              icon="file-text"
              :disabled="disableForm"
              :errors="errorsProduct['tags']"
            />
            <div class="d-flex justify-content-end">
              <b-button
                class=""
                variant="primary"
                :disabled="disableForm"
                @click="createProduct"
              >
                GRAVAR ALTERAÇÕES
              </b-button>
            </div>
          </b-tab>
          <b-tab title="IMAGENS" class="mt-3">
            <upload-image-form
              :images="form.image"
              @added-images="uploadedImages"
              @remove-image="removeImages"
            />
          </b-tab>
          <b-tab title="DICIONÁRIO" class="mt-3">
            <div>
              <form-textarea
                label="Palavras Chave"
                :rows="10"
                :value="form.keyWord"
                icon="ui-checks"
                disabled
              />
              <form-textarea
                label="Erros Ortograficos"
                :rows="10"
                :value="form.misspell"
                icon="type"
                disabled
              />
            </div>
          </b-tab>
          <b-tab title="HISTÓRICO DE PREÇO" class="mt-3" lazy>
            <price-history-table :items="priceHistory" />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import FormInput from "../../components/form/FormInput.vue";
import FormSelect from "../../components/form/FormSelect.vue";
import FormTextarea from "../../components/form/FormTextarea.vue";
import FormNumber from "../../components/form/FormNumber.vue";
import ApplicationForm from "../../components/products/ApplicationForm.vue";
import AttributesTable from "../../components/products/AttributesTable.vue";
import ConversionForm from "../../components/products/ConversionForm.vue";
import PriceHistoryTable from "../../components/products/PriceHistoryTable.vue";
import RelationsTable from "../../components/products/RelationsTable.vue";
import UploadImageForm from "../../components/products/UploadImageForm.vue";
import SearchInput from "../../components/shared/SearchInput.vue";
import http from "../../plugins/http";

const ImageFallback = () => import("../../components/shared/ImageFallback.vue");

export default {
  name: "productDetalis",
  components: {
    RelationsTable,
    PriceHistoryTable,
    AttributesTable,
    ImageFallback,
    ApplicationForm,
    ConversionForm,
    UploadImageForm,
    FormInput,
    FormTextarea,
    FormSelect,
    SearchInput,
    FormNumber,
  },
  data() {
    return {
      showImage: 1,
      imageError: !false,
      pageHeader: {
        code: this.$store.state.product.product.productCode,
        desc: this.$store.state.product.product.completeDescription,
      },
      form: { ...this.$store.state.product.product },
      relations: [],
      priceHistory: [],
      application: { productId: null, value: "" },
      searchProduct: "",
    };
  },
  watch: {
    image: function () {
      this.showImage = this.showImage + 1;
    },
  },
  computed: {
    ...mapState("product", [
      "catalogs",
      "groups",
      "departments",
      "product",
      "isLoadingSearch",
      "errorsProduct",
    ]),
    ...mapGetters("box", ["formatedBoxes"]),
    ...mapGetters("auth", ["userPermission"]),

    disableForm() {
      return this.userPermission([1, 3]);
    },
    image() {
      if (this.form.image[0]) {
        return this.form.image[0].imgUrl;
      }
      return "";
    },
  },
  methods: {
    ...mapActions("product", [
      "updateProduct",
      "getOneProduct",
      "getProductCatalogs",
      "getProductGroup",
      "getProductDepartment",
      "searchProducts",
    ]),
    ...mapActions("box", ["getAllBoxes"]),
    ...mapMutations(["setToasted", "setOverlay"]),

    async search() {
      if (this.searchProduct) {
        await this.searchProducts(this.searchProduct);
        this.$router.push("/produtos");
      }
    },

    async createProduct(event) {
      event.preventDefault();
      await this.updateProduct(this.form);
    },
    async createManyAttributes() {
      const id = this.$route.params.id;
      const { data: attributes } = await http(
        `/product/getProductAttributes/${id}`
      );
      this.form.attributes = attributes;
    },
    deleteAllAttributes() {
      this.form.attributes = [];
    },
    createAtributo(form) {
      this.form.attributes.push(form);
    },
    async updateAtributo(form) {
      const index = this.form.attributes.findIndex((e) => e.id === form.id);
      this.form.attributes.splice(index, 1, form);
    },
    async filterAtributo(atribute) {
      try {
        const url = `product/updateAttributesFilter/${atribute.id}`;
        const { data } = await http.patch(url, { filter: !atribute.filter });
        const index = this.form.attributes.findIndex((e) => e.id === data.id);
        this.form.attributes.splice(index, 1, data);
        this.setToasted({
          title: "SUCESSO",
          message: "Atributo foi atualizado com sucesso",
          variant: "success",
          visible: true,
        });
      } catch (err) {
        this.setToasted({
          title: "ERRO",
          message: err.response.data.error,
          variant: "danger",
          visible: true,
        });
      }
    },
    async removeAtributo(id) {
      try {
        await http.delete(`product/attributes/${id}`);
        this.form.attributes = this.form.attributes.filter((e) => e.id !== id);
        this.setToasted({
          title: "SUCESSO",
          message: "Atributo foi removido com sucesso",
          variant: "success",
          visible: true,
        });
      } catch (err) {
        this.setToasted({
          title: "ERRO",
          message: err.response.data.error,
          variant: "danger",
          visible: true,
        });
      }
    },
    async createConversion(data) {
      this.form.conversion.push(data);
    },
    async updateConversion(data) {
      const index = this.form.conversion.findIndex((e) => e.id === data.id);
      this.form.conversion.splice(index, 1, data);
    },
    async removeConversion(id) {
      this.form.conversion = this.form.conversion.filter((e) => e.id !== id);
    },
    async getPriceHistory() {
      const { form } = this;
      const url = `product/priceHistory/${form.productCode}`;
      const { data } = await http.get(url);
      this.priceHistory = data;
    },
    async getRelations() {
      const { form } = this;
      const url = `product/${form.id}/relation`;
      const { data } = await http.get(url);
      this.relations = data;
    },
    async createRelation(relationId) {
      try {
        const { form } = this;
        const url = `product/${form.id}/relation/${relationId}`;
        await http.post(url);
        this.getRelations();
      } catch (err) {
        this.setToasted({
          title: "ERRO",
          message: err.response.data.error,
          variant: "danger",
          visible: true,
        });
      }
    },
    async removeRelation(id) {
      const { form } = this;
      await http.delete(`product/${form.id}/relation/${id}`);
      this.relations = this.relations.filter((e) => e.id !== id);
    },
    async uploadedImages(data) {
      this.form.image.push(...data);
    },
    async removeImages(id) {
      const index = this.form.image.findIndex((e) => e.id === id);
      this.form.image.splice(index, 1);
    },
    async initialFetch() {
      this.setOverlay(true);
      const id = this.$route.params.id;

      if (id && !this.form.id) {
        await this.getOneProduct(id);
        this.form = { ...this.$store.state.product.product };

        this.pageHeader = {
          code: this.$store.state.product.product.productCode,
          desc: this.$store.state.product.product.completeDescription,
        };
      }

      await Promise.all([
        this.getProductCatalogs(),
        this.getProductGroup(),
        this.getProductDepartment(),
        this.getRelations(),
        this.getPriceHistory(),
        this.getAllBoxes(),
      ]).finally(() => this.setOverlay(false));
    },
  },
  beforeMount() {
    this.initialFetch();
  },
};
</script>

<style lang="scss" scoped>
.apeu-search-input {
  width: 350px;
  margin-left: auto;
}
</style>
