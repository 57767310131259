<template>
  <b-form-group>
    <template v-if="label" v-slot:label>
      <label>
        {{ label }}

        <span v-if="required" class="text-danger"> *</span>
      </label>
    </template>

    <b-input-group>
      <b-input-group-prepend
        v-if="icon"
        is-text
        :class="{ 'icon-button': copyable }"
        :title="copyable && 'Copiar texto para área de transferência'"
        @click="useClipboard"
      >
        <b-icon :icon="icon" />
      </b-input-group-prepend>

      <b-form-textarea
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :required="required"
        :rows="rows"
        :max-rows="maxRows"
        :no-resize="noResize"
        :state="validationState"
        @input="$emit('input', $event)"
      />

      <b-form-invalid-feedback>
        {{ validationMessage }}
      </b-form-invalid-feedback>
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  name: "FormTextarea",
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    icon: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    rows: {
      type: [String, Number],
      required: false,
    },
    maxRows: {
      type: [String, Number],
      required: false,
    },
    noResize: {
      type: Boolean,
      required: false,
    },
    state: {
      type: Boolean,
      required: false,
      default: undefined,
    },
    copyable: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    validationState() {
      return this.errors[0] ? false : null;
    },
    validationMessage() {
      return this.errors[0];
    },
  },
  methods: {
    useClipboard() {
      if (this.copyable) {
        navigator.clipboard.writeText(this.value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-button {
  cursor: pointer;
}
</style>
