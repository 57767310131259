<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-form @submit="submitForm">
          <form-textarea
            v-model="applicationComputed"
            label="Aplicação:"
            placeholder="Digite a aplicação:"
            :rows="15"
            :max-rows="30"
            :errors="apiError['application']"
          />

          <div class="d-flex justify-content-end mt-3">
            <b-button type="submit" variant="primary">
              SALVAR APLICAÇÃO
            </b-button>
          </div>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import http from "../../plugins/http";
import FormTextarea from "../form/FormTextarea.vue";

export default {
  components: { FormTextarea },
  name: "ApplicationForm",
  props: {
    application: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      applicationForm: "",
      apiError: {},
    };
  },
  computed: {
    ...mapState("product", ["product"]),

    applicationComputed: {
      get() {
        return this.application.value;
      },
      set(value) {
        this.applicationForm = value;
      },
    },
  },
  methods: {
    ...mapMutations(["setToastedSuccess", "setToastedError"]),

    submitForm(event) {
      event.preventDefault();

      if (this.application.id) {
        this.update();
      } else {
        this.save();
      }
    },
    async save() {
      try {
        const payload = {
          productId: this.product.id,
          application: this.applicationForm,
        };

        const { data } = await http.post(`/application`, payload);

        this.applicationForm = data;
        this.setToastedSuccess({
          message: "Aplicação foi criada com sucesso",
        });
      } catch (err) {
        if (err.response.status === 406) {
          this.apiError = err.response.data.error;
        } else {
          this.setToastedError({
            message: err.response.data.error,
          });
        }
      }
    },
    async update() {
      try {
        const { applicationComputed, applicationForm, application } = this;
        const payload = {
          application: applicationForm || applicationComputed,
        };

        const { data } = await http.put(
          `/application/${application.id}`,
          payload
        );

        this.applicationForm = data;
        this.setToastedSuccess({
          message: "Aplicação foi atualizada com sucesso",
        });
      } catch (err) {
        this.setToastedError({
          message: "Não foi possível atualizar a aplicação",
        });
      }
    },
    async remove() {
      const { application } = this;
      await http.put(`/application/${application.id}`);
    },
  },
};
</script>

<style></style>
